import React from 'react';
import DisplayForm from './displayForm';


const SiteEvaluation: React.FC = () => {

  return (
    <div>
      <DisplayForm.DisplayForm />
    </div>
  );
}



export default SiteEvaluation;